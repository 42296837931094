import auth from "@/middleware/auth";

export default [
  {
    path: '/users/admin-users/list',
    name: 'users-users-list',
    component: () => import('@/views/user/admin-user/users-list/UsersList.vue'),
    meta: {
      pageTitle: "Admin-List",
      breadcrumb: [
        {
          text: "User-Admin",
          active: true,
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'User Admin', type: 'list' }
    },
    beforeEnter: auth,
  },
  {
    path: '/users/admin-users/add',
    name: 'user-add',
    component: () => import('@/views/user/admin-user/user-add/UserAddEdit.vue'),
    meta: {
      pageTitle: "Admin-Add",
      breadcrumb: [
        {
          text: "User-Admin",
          to: "/users/admin-users/list"
        },
        {
          text: "Add",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'User Admin', type: 'add' }
    },
    beforeEnter: auth,
  },
  {
    path: '/users/admin-users/edit/:id',
    name: 'user-edit',
    component: () => import('@/views/user/admin-user/user-add/UserAddEdit.vue'),
    meta: {
      pageTitle: "Admin-Edit",
      breadcrumb: [
        {
          text: "User-Admin",
          to: "/users/admin-users/list"
        },
        {
          text: "Edit",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'User Admin', type: 'edit' }
    },
    beforeEnter: auth,
  }
]
