import auth from "@/middleware/auth";

export default [
  {
    path: '/currency/list',
    name: 'currency-list',
    component: () => import('@/views/currency/currency-list/CurrencyList.vue'),
    meta: {
      pageTitle: "Currency-List",
      breadcrumb: [
        {
          text: "Currency",
          active: true
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Currency', type: 'list' }
    },
    beforeEnter: auth,

  },

  {
    path: '/currency/edit/:id',
    name: 'currency-edit',
    component: () => import('@/views/currency/currency-edit/CurrencyEdit.vue'),
    meta: {
      pageTitle: "Currency-Edit",
      breadcrumb: [
        {
          text: "Currency-Edit",
          to: '/currency/list'
        },
        {
          text: "Edit",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Currency', type: 'edit' }
    },
    beforeEnter: auth,

  },

]
