import auth from "@/middleware/auth";

export default [
    {
        path: "/content/page-meta",
        name: "page-meta",
        component: () =>
            import(
                "@/views/content-admin/page-meta/meta-list/PageMetaList.vue"
            ),
        meta: {
            pageTitle: "Page-Meta",
            breadcrumb: [
                {
                    text: "Page-Meta",
                    active: true,
                },
                {
                    text: "List",
                    active: true,
                },
            ],
            requiresAuth: true,
            module: { name: "Page meta", type: "list" },
        },
        beforeEnter: auth,
    },

    {
        path: "/content/edit-page-meta/:id",
        name: "page-meta-edit",
        component: () =>
            import(
                "@/views/content-admin/page-meta/meta-edit/PageMetaEdit.vue"
            ),
        meta: {
            pageTitle: "Page-Meta",
            breadcrumb: [
                {
                    text: "Page-Meta",
                    to: { name: "page-meta" },
                },
                {
                    text: "Edit",
                    active: true,
                },
            ],
            requiresAuth: true,
            module: { name: "Page meta", type: "edit" },
        },
        beforeEnter: auth,
    },
]