export const roleOptions = [
  { label: "Admin", value: 2 },
  { label: 'Support Admin', value: 4 },
  // { label: 'User', value: 6 }
];
export const genderOptions = [
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
  { label: "Other", value: "O" }
];
export const resolveUserRoleIcon = role => {
  if (role === 5) return "UserIcon";
  if (role === 4) return "UserIcon";
  if (role === 2) return "ServerIcon";
  return "UserIcon";
};
export const resolveUserRoleVariant = role => {
  if (role === 5) return "success";
  if (role === 4) return "info";
  if (role === 2) return "danger";
  return "primary";
};
export const resolveUserStatusVariant = status => {
  if (status === true) return "success";
  if (status === false) return "secondary";
  return "primary";
};

export const resolveBookingStatusVariant = status => {
  if (status === 0) return "warning";
  if (status === 1) return "success";
  if (status === 2) return "danger";
  if (status === 3) return "secondary";
  return "";
};

export const extrasInitialVariant = status => {
  if (status === "Pending" || status === "1") return "light-warning";
  if (status === "Confirmed" || status === "0") return "light-success";
  if (status === "Cancelled" || status === "3") return "light-secondary";

};

export const resolveMainBookingStatusVariant = status => {
  if (status === "Pending") return "warning";
  if (status === "Confirmed") return "success";
  if (status === "Failed") return "danger";
  if (status === "Cancelled") return "secondary";
  return "";
};

export const resolveMainBookingRequestStatusVariant = status => {
  if (status === "Pending") return "warning";
  if (status === "Completed") return "success";
  if (status === "Ongoing") return "secondary";
  return "";
};
export const resolveUserRoleName = role => {
  if (role === 7) return "External-Agent";
  if (role === 5) return "Customer";
  if (role === 4) return "Support-Admin";
  if (role === 2) return "Admin";
  if (role === 1) return "Super-Admin";
  return "";
};
export const resolveUserStatusName = status => {
  if (status === true) return "Active";
  if (status === false) return "Inactive";
  return "primary";
};
export const customerRole = [
  { label: "Customer", value: 5 },
  { label: "External Agent", value: 7 }
];
export const customerFilter = [
  { label: "Customer", value: 1 },
  { label: "External Agent", value: 0 }
];
export const adminFilter = [
  { label: "Admin", value: 1 },
  { label: "Support Admin", value: 0 }
];
export const roleFilter = [
  { label: "Admin", value: 2 },
  { label: "Support Admin", value: 4 }
];
export const perPageOptions = [10, 25, 50, 100];
export const name = "Beontrips";

export const perPage = 10;

export const currentPage = 1;

export const serverMsg = "Service is not available.Please try again later";

export const deleteConfirmMsg = "Delete Confirmation";

export const newsletterCheck = status => {
  if (status === true) return "Yes";
  if (status === false) return "No";
  return "";
};

/* export const resConditionCheck = statusText => {
  if (statusText === "OK") return "OK";
  if (statusText === "Created") return "Created";
  return "";
}; */
export const resConditionCheck = status => {
  if (status == 200) return "OK";
  if (status == 201) return "Created";
  return "";
};

export const statusFilter = [
  { label: "Active", value: 1 },
  { label: "InActive", value: 0 }
];

export const offerStatusFilter = [
  { label: "Published", value: 1 },
  { label: "Unpublished", value: 2 },
  { label: "Expired", value: 3 }
];

export const tripTypeFilter = [
  { label: "Hotel", value: 1 },
  { label: "Hotel + Flight", value: 3 },
  { label: "Flight", value: 2 }
];
export const layoutOptions = [
  { label: "Small", value: "1/4" },
  { label: "Medium", value: "1/2" },
  { label: "Large", value: "1" }
];
export const hotelFlightOptions = [
  { label: "Hotels Only", value: 0 },
  { label: "Hotels + Flights", value: 1 }
];

export const PaymentChangeModuleOptions = [
  { label: "Hotel", value: "Hotel" },
  { label: "Flight", value: "Flight" }
];

export const PaymentOnlyHotelOptions = [
  { label: "Hotel", value: "Hotel" }
];

export const PaymentOnlyFlightOptions = [
  { label: "Flight", value: "Flight" }
];

export const faqCategories = [
  { label: "General", value: 0 },
  { label: "Bookings", value: 1 },
  { label: "Account", value: 2 }
];

export const PaymentInstallmentType = [
  { label: "Full payment", value: 1 },
  { label: "Installment", value: 2 }
];

export const BookingStatusEnum = [
  { label: "Pending", value: 0 },
  { label: "Confirmed", value: 1 },
  { label: "Failed", value: 2 },
  { label: "Cancelled", value: 3 }
];

export const BookingRequestStatusEnum = [
  { label: "Pending", value: 0 },
  { label: "Ongoing", value: 1 },
  { label: "Completed", value: 2 },
];

export const PriorityBoardingEnum = [
  { label: "Pending", value: "Pending" },
  {label: "Confirmed", value: "Confirmed"},
  {label: "Cancelled", value: "Cancelled"}
  
]

export const BaggageStatusEnum = [
  { label: "Pending", value: "Pending" },
  { label: "Confirmed", value: "Confirmed" },
  { label: "Cancelled", value: "Cancelled" }

]

export const TransferStatusEnum = [
  { label: "Pending", value: "Pending" },
  { label: "Confirmed", value: "Confirmed" },
  { label: "Cancelled", value: "Cancelled" }

]

export const TransferTypeEnum = [
  { label: "Private Transfer", value: "Private Transfer" },
  {label: "Public Shuttle Transfer", value: "Public Shuttle Transfer"}
]

export const PaymentStatusEnum = [
  { label: "Pending", value: 0 },
  { label: "Success", value: 1 },
  { label: "Failed", value: 2 }
];

export const WhiteLabelEnum = [{ label: "Beontrips", value: 1 }];

export const PaymentTypeEnum = [
  { label: "Card", value: 1 },
  { label: "Bank Transfer", value: 2 }
];

export const AgencyEnum = [
  { label: "Customer", value: 1 },
  { label: "Agent", value: 2 }
];

export const FlightExcludeEnum = [
  { label: "Hotel", value: 1 },
  { label: "Hotel + Flight", value: 3 }
];

export const BoardBasisEnum = [
  { label: "Rooms Only", value: 'RO' },
  { label: "Bed and Breakfast", value: 'BB' },
  { label: "Half Board", value: 'HB' },
  { label: "Full Board", value: 'FB' },
  { label: "All Inclusive", value: 'AI' },

];


export const BoardBasis = board => {
  if (board === "RO") return "Rooms Only";
  if (board === "BB") return "Bed and Breakfast";
  if (board === "HB") return "Half Board";
  if (board === "FB") return "Full Board";
  if (board === "AI") return "All Inclusive";
  return "-";
};
export const RoleEnum = {
  SuperAdmin: 1,
  Admin: 2,
  SupportAgent: 4,
  Customer: 5,
  CustomerAgent: 7,
};

export const LanguageEnum = {
  English: 1
};

export const BookingModeEnum = [
  { label: "Web", value: 1 },
  { label: "Manual", value: 2 }
];

export const StarRatingEnum = [
  { label: "1 Star", value: 1 },
  { label: "2 Star", value: 2 },
  { label: "3 Star", value: 3 },
  { label: "4 Star", value: 4 },
  { label: "5 Star", value: 5 }
];

export const TravelerTitles = [
  { label: "Mr.", value: "Mr" },
  { label: "Mrs.", value: "Mrs" },
  { label: "Ms.", value: "Ms" }
];

export const InsuranceStatus = [
  { label: "Confirmed", value: "Confirmed" },
  { label: "Pending", value: "Pending" },
  { label: "Failed", value: "Failed" },
  { label: "Cancelled", value: "Cancelled" }
];

export const MonthsFilter = [
  { label: "January", value: "1" },
  { label: "February", value: "2" },
  { label: "March", value: "3" },
  { label: "April", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "August", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
]

export const YearsFilter = [
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
  { label: "2024", value: "2024" },
  { label: "2025", value: "2025" },
]

export const MonthName = month => {
  if (month === 1) return "January";
  if (month === 2) return "February";
  if (month === 3) return "March";
  if (month === 4) return "April";
  if (month === 5) return "May";
  if (month === 6) return "June";
  if (month === 7) return "July";
  if (month === 8) return "August";
  if (month === 9) return "September";
  if (month === 10) return "October";
  if (month === 11) return "November";
  if (month === 12) return "December";
}

export const YearName = year => {
  if (year === 2022) return "2022";
  if (year === 2023) return "2023";
  if (year === 2024) return "2024";
  if (year === 2025) return "2025";
}

export const TripType = type => {
  if (type === 1) return "Hotel";
  if (type === 2) return "Flight";
  if (type === 3) return "Hotel + Flight";
  return "";
};

export const offerStatus = status => {
  if (status === 1) return "Published";
  if (status === 2) return "Unpublished";
  if (status === 3) return "Expired";
  return "";
};

export const BookingStatus = status => {
  if (status === 0) return "Pending";
  if (status === 1) return "Confirmed";
  if (status === 2) return "Failed";
  if (status === 3) return "Cancelled";
  return "";
};

export const WhiteLabel = code => {
  if (code === 1) return "Beontrips";
  return "";
};

export const PaymentType = type => {
  if (type === 1) return "Card";
  if (type === 2) return "Bank Transfer";
  return "";
};

export const AgencyStatus = status => {
  if (status === 1) return "Customer";
  if (status === 2) return "Agent";
  return "";
};

export const TravelerPrefix = [
  { label: "Mr.", value: "MR" },
  { label: "Mrs.", value: "MRS" },
  { label: "Ms.", value: "MS" }
];

export const NotFound = {
  LOCATION_NOT_FOUND: "No locations found.",
  MEDIA_NOT_FOUND: "No media found.",
  NOT_FOUND: "No matching records found.",
  TRENDING_NOT_FOUND: "No records found.",
  THEME_NOT_FOUND: "No themes found."
};

export const PaymentStatus = status => {
  if (status === 0) return "Pending";
  if (status === 1) return "Success";
  if (status === 2) return "Failed";
  return "";
};

export const InstallmentType = type => {
  if (type === 1) return "Full";
  if (type === 2) return "Installment";
  return "";
};

export const TravelerTitleType = type => {
  if (type === "Mr.") return "Mr";
  if (type === "Mrs.") return "Mrs";
  if (type === "Ms.") return "Ms";
  return "";
};

export const ImageError = {
  Banner_Not_Found: "Please upload banner image",
  Theme_Image_Error: "Theme image is required"
};

export const priority = Array.from(new Array(20), (x, i) => ({
  label: i + 1,
  value: i + 1
}));

export const adults = Array.from(new Array(6), (x, i) => ({
  text: i + 1,
  value: i + 1
}));

export const child = Array.from(new Array(7), (x, i) => ({
  text: i,
  value: i
}));

export const rating = Array.from(new Array(10), (x, i) => ({
  label: i + 1,
  value: i + 1
}));

export const decimalRating = () => {
  let count = 0
  let arr = []
  for (let i = count; i < 0.9; i += 0.1) {
    arr.push(+i.toFixed(1))
  }
  return arr
}

export const SeoMetaTypeOptions = [
  { label: "Meta", value: "Meta" },
  { label: "OG", value: "OG" },
  { label: "Twitter", value: "Twitter" },
];

export const RobotIndexTypes = [
  { label: "Index", value: "index" },
  { label: "No Index", value: "noindex" },
];

export const RobotFollowTypes = [
  { label: "Follow", value: "follow" },
  { label: "No Follow", value: "nofollow" },
];

export const OGTypes = [
  { label: "Website", value: "website" },
  { label: "Article", value: "article" },
];

export default {
  roleOptions,
  genderOptions,
  resolveUserRoleIcon,
  resolveUserRoleVariant,
  resolveUserStatusVariant,
  resolveUserRoleName,
  resolveUserStatusName,
  perPageOptions,
  perPage,
  currentPage,
  serverMsg,
  name,
  customerRole,
  customerFilter,
  newsletterCheck,
  RoleEnum,
  statusFilter,
  faqCategories,
  roleFilter,
  hotelFlightOptions,
  layoutOptions,
  priority,
  NotFound,
  ImageError,
  LanguageEnum,
  BookingStatus,
  resolveBookingStatusVariant,
  AgencyStatus,
  PaymentStatus,
  WhiteLabelEnum,
  StarRatingEnum,
  InstallmentType,
  TravelerTitles,
  TravelerTitleType,
  BoardBasis,
  PaymentStatusEnum,
  PriorityBoardingEnum,
  BaggageStatusEnum,
  TransferStatusEnum,
  MonthName,
  MonthsFilter,
  YearName,
  YearsFilter,
  SeoMetaTypeOptions
};
