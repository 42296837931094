import auth from "@/middleware/auth";

export default [
  {
    path: '/amenities/list',
    name: 'amenities-list',
    component: () => import('@/views/amenities/amenities-list/AmenitiesList.vue'),
    meta: {
      pageTitle: "Amenities List",
      breadcrumb: [
        {
          text: "Amenities",
          active: true,
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Amenities', type: 'list' }
    },
    beforeEnter: auth,

  },

  {
    path: '/amenities/edit/:id',
    name: 'amenities-edit',
    component: () => import('@/views/amenities/amenities-edit/AmenitiesEdit.vue'),
    meta: {
      pageTitle: "Amenities Edit",
      breadcrumb: [
        {
          text: "Amenities",
          to: { name: 'amenities-list' }
        },
        {
          text: "Edit",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Amenities', type: 'edit' }
    },
    beforeEnter: auth,

  },

]
