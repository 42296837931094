import auth from '@/middleware/auth'

export default [
  {
    path: '/saved-trips/list',
    name: 'saved-trips',
    component: () => import('@/views/saved-trips/saved-trips-list/SavedTrips.vue'),
    meta: {
      pageTitle: "Saved-Trips",
      breadcrumb: [
        {
          text: "Saved-Trips",
          active: true
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Saved Trips', type: 'list' }

    },
    beforeEnter: auth,

  },
]
