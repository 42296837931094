import auth from "@/middleware/auth";

export default [
  {
    path: '/customers/customer-users/list',
    name: 'customers-customers-list',
    component: () => import('@/views/user/customer-user/customers-list/CustomersList.vue'),
    meta: {
      pageTitle: "Customer-List",
      breadcrumb: [
        {
          text: "Customer-Admin",
          active: true,
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Customer Admin', type: 'list' }
    },
    beforeEnter: auth,
  },
  {
    path: '/customers/customer-users/add',
    name: 'customer-add',
    component: () => import('@/views/user/customer-user/customer-add/CustomerAddEdit.vue'),
    meta: {
      pageTitle: "Customer-Add",
      breadcrumb: [
        {
          text: "Customer-Admin",
          to: '/customers/customer-users/list'
        },
        {
          text: "Add",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Customer Admin', type: 'add' }
    },
    beforeEnter: auth,
  },
  {
    path: '/Customer/customer-users/edit/:id',
    name: 'customer-edit',
    component: () => import('@/views/user/customer-user/customer-add/CustomerAddEdit.vue'),
    meta: {
      pageTitle: "Customer-Edit",
      breadcrumb: [
        {
          text: "Customer-Admin",
          to: '/customers/customer-users/list'
        },
        {
          text: "Edit",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Customer Admin', type: 'edit' }
    },
    beforeEnter: auth,
  }
]
