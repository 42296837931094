import Vue from "vue";
import VueRouter from "vue-router";
import auth from "@/middleware/auth";
import users from './routes/users';
import language from "./routes/language";
import customer from './routes/customer';
import currency from "./routes/currency"
import content from './routes/content'
import userRights from './routes/userRights'
import logs from './routes/logs'
import faq from './routes/faq'
import bannerManagement from './routes/bannerManagement'
import savedTrips from './routes/savedTrips'
import offers from './routes/offer'
import settings from './routes/settings'
import booking from './routes/booking'
import hotelContent from './routes/hotelContent'
import amenities from './routes/amenities'
import whitelabel from './routes/whitelabel'
import pageMeta from "./routes/pageMeta";
import bookingRequest from "./routes/bookingRequest";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [

    {
      path: '/',
      redirect: '/dashboard'
    },
    ...whitelabel,
    ...users,
    ...customer,
    ...language,
    ...currency,
    ...content,
    ...userRights,
    ...logs,
    ...faq,
    ...bannerManagement,
    ...savedTrips,
    ...offers,
    ...settings,
    ...booking,
    ...hotelContent,
    ...amenities,
    ...pageMeta,
    ...bookingRequest,
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/views/dashboard/Analytics.vue"),
      meta: {
        pageTitle: "Dashboard",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
        requiresAuth: true,
        module: { name: 'Dashboard', type: 'list' }
      },
      beforeEnter: auth,
    },

    {
      path: "/profile",
      name: "profile",
      component: () => import("@/views/profile/Profile.vue"),
      meta: {
        pageTitle: "Profile",
        breadcrumb: [
          {
            text: "Profile",
            active: true,
          },
        ],
        requiresAuth: true,
      },
      beforeEnter: auth,
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
        hideForAuth: true,
      },
      beforeEnter: auth,
    },

    {
      path: "/forgot-password",
      name: "forgot-password",
      component: () => import("@/views/auth/ForgotPassword.vue"),
      meta: {
        layout: "full",
        hideForAuth: true,
      },
      beforeEnter: auth,
    },

    {
      path: `/reset-password`,
      name: "reset-password",
      props: route => ({ query: route.query.token }),
      component: () => import("@/views/auth/ResetPassword.vue"),
      meta: {
        layout: "full",
        hideForAuth: true,
      },
      beforeEnter: auth,
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
