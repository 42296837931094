import auth from "@/middleware/auth";

export default [
  {
    path: '/hotel-content/list',
    name: 'hotel-content-list',
    component: () => import('@/views/hotel-content/content-list/ContentList.vue'),
    meta: {
      pageTitle: "Hotel Content List",
      breadcrumb: [
        {
          text: "Hotel Content",
          active: true
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Hotel Content', type: 'list' }
    },
    beforeEnter: auth,

  },

  {
    path: '/hotel-content/edit/:id',
    name: 'hotel-content-edit',
    component: () => import('@/views/hotel-content/content-edit/ContentEdit.vue'),
    meta: {
      pageTitle: "Hotel Content Edit",
      breadcrumb: [
        {
          text: "Hotel Content",
          to: { name: 'hotel-content-list' },
        },
        {
          text: "Edit",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Hotel Content', type: 'list' }
    },
    beforeEnter: auth,

  },

  {
    path: '/disabled-hotels/list',
    name: 'disabled-hotel-list',
    component: () => import('@/views/hotel-content/disable-list/DisableList.vue'),
    meta: {
      pageTitle: "Disabled Hotel List",
      breadcrumb: [
        {
          text: "Disabled Hotel List",
          active: true
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Disabled Hotel List', type: 'list' }
    },
    beforeEnter: auth,

  },

  {
    path: '/disabled-hotels/add',
    name: 'disabled-hotel-add',
    component: () => import('@/views/hotel-content/disable-add/DisableAddEdit.vue'),
    meta: {
      pageTitle: "Disabled-Hotel-Add",
      breadcrumb: [
        {
          text: "Disabled Hotel Add",
          to: { name: 'disabled-hotel-list' },
        },
        {
          text: "Add",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Disabled Hotel Add', type: 'add' }

    },
    beforeEnter: auth,

  },

  {
    path: '/disabled-hotels/edit/:id',
    name: 'disabled-hotel-edit',
    component: () => import('@/views/hotel-content/disable-add/DisableAddEdit.vue'),
    meta: {
      pageTitle: "Disabled-Hotel-Edit",
      breadcrumb: [
        {
          text: "Disabled Hotel Edit",
          to: { name: 'disabled-hotel-list' },
        },
        {
          text: "Edit",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Disabled Hotel Edit', type: 'edit' }

    },
    beforeEnter: auth,

  },

]
