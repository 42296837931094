import auth from "@/middleware/auth";

export default [
  {
    path: '/whitelabel/list',
    name: 'whitelabel-list',
    component: () => import('@/views/whitelabel/whitelabel-list/WhitelabelList.vue'),
    meta: {
      pageTitle: "White Label List",
      breadcrumb: [
        {
          text: "Whitelabel",
          active: true,
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Whitelabel', type: 'list' }
    },
    beforeEnter: auth,

  },
  {
    path: '/whitelabel/add',
    name: 'whitelabel-add',
    component: () => import('@/views/whitelabel/whitelabel-add/WhiteLabelAddEdit.vue'),
    meta: {
      pageTitle: "Add White Label",
      breadcrumb: [
        {
          text: "Whitelabel",
          to: "/whitelabel/list"
        },
        {
          text: "Add",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Whitelabel', type: 'add' }
    },
    beforeEnter: auth,

  },
  {
    path: '/whitelabel/edit/:id',
    name: 'whitelabel-edit',
    component: () => import('@/views/whitelabel/whitelabel-add/WhiteLabelAddEdit.vue'),
    meta: {
      pageTitle: "Edit White Label",
      breadcrumb: [
        {
          text: "Whitelabel",
          to: "/whitelabel/list"
        },
        {
          text: "Edit",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Whitelabel', type: 'edit' }
    },
    beforeEnter: auth,

  },
  {
    path: '/whitelabel/add',
    name: 'login-as',
    component: () => import('@/views/whitelabel/whitelabel-add/WhiteLabelAddEdit.vue'),
    meta: {
      pageTitle: "Add White Label",
      breadcrumb: [
        {
          text: "Whitelabel",
          to: "/whitelabel/list"
        },
        {
          text: "Edit",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Whitelabel', type: 'edit' }
    },
    beforeEnter: auth,

  },
]
