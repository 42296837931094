import auth from '@/middleware/auth'

export default [
  // Theme Routes
  {
    path: '/theme/list',
    name: 'theme-list',
    component: () => import('@/views/banner-management/themes/theme-list/ThemeList.vue'),
    meta: {
      pageTitle: "Theme-List",
      breadcrumb: [
        {
          text: "Theme",
          active: true
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Themes', type: 'list' }

    },
    beforeEnter: auth,

  },

  {
    path: '/theme/add',
    name: 'theme-add',
    component: () => import('@/views/banner-management/themes/theme-add/ThemeAddEdit.vue'),
    meta: {
      pageTitle: "Theme-Add",
      breadcrumb: [
        {
          text: "Theme",
          to: { name: 'theme-list' }
        },
        {
          text: "Add",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Themes', type: 'add' }

    },
    beforeEnter: auth,

  },

  {
    path: '/theme/edit/:id',
    name: 'theme-edit',
    component: () => import('@/views/banner-management/themes/theme-add/ThemeAddEdit.vue'),
    meta: {
      pageTitle: "Theme-Edit",
      breadcrumb: [
        {
          text: "Theme",
          to: { name: 'theme-list' }
        },
        {
          text: "Edit",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Themes', type: 'edit' }

    },
    beforeEnter: auth,

  },

  // ------------------Location Routes--------------------------------

  {
    path: '/location/list',
    name: 'location-list',
    component: () => import('@/views/banner-management/location/location-list/LocationList.vue'),
    meta: {
      pageTitle: "Location-List",
      breadcrumb: [
        {
          text: "Location",
          active: true
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Locations', type: 'list' }

    },
    beforeEnter: auth,

  },

  {
    path: '/location/add',
    name: 'location-add',
    component: () => import('@/views/banner-management/location/location-add/LocationAddEdit.vue'),
    meta: {
      pageTitle: "Location-Add",
      breadcrumb: [
        {
          text: "Location",
          to: { name: 'location-list' }
        },
        {
          text: "Add",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Locations', type: 'add' }

    },
    beforeEnter: auth,

  },

  {
    path: '/location/edit/:id',
    name: 'location-edit',
    component: () => import('@/views/banner-management/location/location-add/LocationAddEdit.vue'),
    meta: {
      pageTitle: "Location-Edit",
      breadcrumb: [
        {
          text: "Location",
          to: { name: 'location-list' }
        },
        {
          text: "Edit",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Locations', type: 'edit' }

    },
    beforeEnter: auth,

  },

  // ------------------Magazine Media Routes--------------------------------

  {
    path: '/media/list',
    name: 'magazine-media',
    component: () => import('@/views/banner-management/magazine-media/media-list/MediaList.vue'),
    meta: {
      pageTitle: "Magazine Media",
      breadcrumb: [
        {
          text: "Magazine-Media",
          active: true
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Magazine Media', type: 'list' }

    },
    beforeEnter: auth,

  },

  {
    path: '/media/add',
    name: 'media-add',
    component: () => import('@/views/banner-management/magazine-media/media-add/MediaAddEdit.vue'),
    meta: {
      pageTitle: "Magazine Media",
      breadcrumb: [
        {
          text: "Media",
          to: { name: 'magazine-media' }
        },
        {
          text: "Add",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Magazine Media', type: 'add' }

    },
    beforeEnter: auth,

  },

  {
    path: '/media/edit/:id',
    name: 'media-edit',
    component: () => import('@/views/banner-management/magazine-media/media-add/MediaAddEdit.vue'),
    meta: {
      pageTitle: "Magazine Media",
      breadcrumb: [
        {
          text: "Media",
          to: "/media/list"
        },
        {
          text: "Edit",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Magazine Media', type: 'edit' }

    },
    beforeEnter: auth,

  },

  // ------------------Trending Now Routes--------------------------------

  {
    path: '/trending/list',
    name: 'trending-now',
    component: () => import('@/views/banner-management/trending-now/trending-list/TrendingNowList.vue'),
    meta: {
      pageTitle: "Trending Now",
      breadcrumb: [
        {
          text: "Trending Now",
          active: true
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Trending Now', type: 'list' }

    },
    beforeEnter: auth,

  },

  {
    path: '/trending/edit/:id',
    name: 'trending-now-edit',
    component: () => import('@/views/banner-management/trending-now/trending-edit/TrendingNowEdit.vue'),
    meta: {
      pageTitle: "Edit Trending Now",
      breadcrumb: [
        {
          text: "Trending Now",
          to: '/trending/list'
        },
        {
          text: "Edit",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Trending Now', type: 'edit' }

    },
    beforeEnter: auth,

  },
]
