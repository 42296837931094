import store from '@/store'
import { RoleEnum } from '@/helpers/constant'

export default function (to, from, next) {
  if (to.matched.some(route => route.meta.requiresAuth)) {
    if (!store.getters['auth/authenticated']) {
      next({ path: '/login' })
    } else {
      if (to.meta?.module && (store.getters['auth/authenticated'].roleId === RoleEnum.Admin || store.getters['auth/authenticated'].roleId === RoleEnum.SupportAgent)) {
        store.getters['auth/authenticated'].rights.module.forEach(module => {
          if (to.meta.module.name === module.name) {
            if (module.name === 'Dashboard' && module.view === false && store.getters['auth/authenticated'].roleId === RoleEnum.SupportAgent) {
              next({path: '/booking/list'}) 
            } else {
              if (to.meta.module.type === 'list') {
                if (module.view === false) {
                  next({ path: '/error-404' })
                }
              } else if (to.meta.module.type === 'add') {
                if (module.Add === false) {
                  next({ path: '/error-404' })
                }
              } else if (to.meta.module.type === 'edit') {
                if (module.Edit === false) {
                  next({ path: '/error-404' })
                }
              } else {
                next()
              }
            }
          }
        });
      }
      next()
    }
  } else {
    next()
  }

  if (to.matched.some(route => route.meta.hideForAuth)) {
    if (store.getters['auth/authenticated']) {
      next({ path: '/dashboard' })
    } else {
      next()
    }
  } else {
    next()
  }
}
