import auth from '@/middleware/auth'

export default [
  {
    path: '/settings/list',
    name: 'settings',
    component: () => import('@/views/settings/date-settings/Settings.vue'),
    meta: {
      pageTitle: "Settings",
      breadcrumb: [
        {
          text: "Settings",
          active: true
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
    },
    beforeEnter: auth,
    module: { name: 'Date Settings', type: 'list' }

  },

  {
    path: '/settings/contact-settings',
    name: 'contact-settings',
    component: () => import('@/views/settings/contact-settings/ContactSettings.vue'),
    meta: {
      pageTitle: "Settings",
      breadcrumb: [
        {
          text: "Settings",
          active: true
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Contact Settings', type: 'list' }

    },
    beforeEnter: auth,

  },
]
