import auth from "@/middleware/auth";

export default [
  {
    path: '/language/list',
    name: 'language-list',
    component: () => import('@/views/language/language-list/LanguageList.vue'),
    meta: {
      pageTitle: "Language-List",
      breadcrumb: [
        {
          text: "Language",
          active: true
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Language', type: 'list' }
    },
    beforeEnter: auth,

  },

  {
    path: '/language/edit/:id',
    name: 'language-edit',
    component: () => import('@/views/language/language-edit/LanguageEdit.vue'),
    meta: {
      pageTitle: "Language-Edit",
      breadcrumb: [
        {
          text: "Language-Edit",
          to: { name: 'language-list' }
        },
        {
          text: "Edit",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Language', type: 'edit' }
    },
    beforeEnter: auth,
  }
]
