import axios from 'axios'

export default {
  namespaced: true,
  state: {
    token: 'null',
    user: 'null',
    configData: '',
    currencyData: null,
  },

  getters: {
    authenticated(state) {
      return state.token && state.user
    },

    user(state) {
      return state.user
    },
    currencyData: state => state.currencyData,
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_USER(state, data) {
      state.user = data
    },
    SET_CONFIG(state, data) {
      state.configData = data;
    },
    SET_CURRENCY(state, val) {
      state.currencyData = val;
    }
  },

  actions: {
    async signIn({ dispatch }, credentials) {
      const response = await axios.post('auth/login', credentials)
      return dispatch('attempt', response.data.user_details.token)
    },

    async attempt({ commit, state }, token) {
      if (token) {
        commit('SET_TOKEN', token)
      }
      if (!state.token) {
        return
      }

      try {
        const response = await axios.get('auth/profile')
        await commit('SET_USER', response.data.data)
        if (response.data.data.roleId === 1 && !localStorage.getItem('signinWLAdmin')) {
          localStorage.setItem('superAdmin_userId', response.data.data.userId)
        } else {
          if ((!response.data.data.isActive || response.data.data.isDeleted) && !localStorage.getItem('signinAsAdmin')) {
            commit('SET_TOKEN', null)
            commit('SET_USER', null)
          }
          // localStorage.removeItem("signinWLAdmin");
          // localStorage.removeItem("whitelabel_code");
        }
      } catch (e) {
        localStorage.removeItem("signinWLAdmin");
        localStorage.removeItem("whitelabel_code");
        commit('SET_TOKEN', null)
        commit('SET_USER', null)
      }
    },

    async getConfig({ commit }) {
      try {
        const configData = await axios.get(`white-label/get-wl-config/${axios.defaults.headers.wl_code}`);
        commit('SET_CONFIG', configData.data.data)
      } catch (error) {
        commit('SET_CONFIG', '')
        console.log(error)          
      }
    },
    async setCurrency({commit}, wl_code) {
      try {
        let wlCode = axios.defaults.headers.wl_code
        wl_code = wl_code !== 'all' ? wl_code : 1
        if(wl_code){
            axios.defaults.headers.wl_code = wl_code
        }
        const currencyData = await axios.get(`currency`);
        axios.defaults.headers.wl_code = wlCode;
        let currency = currencyData.data.data.map((e) => { return e.symbol})
        commit('SET_CURRENCY', currency[0]);
      } catch (error) {
        commit('SET_CONFIG', '')
        console.log(error)          
      }
    },
  },
}
