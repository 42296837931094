import auth from '@/middleware/auth'

export default [
  {
    path: '/faq/list',
    name: 'faq-list',
    component: () => import('@/views/faq/faq-list/FaqList.vue'),
    meta: {
      pageTitle: "FAQ-List",
      breadcrumb: [
        {
          text: "FAQ",
          active: true
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'FAQ', type: 'list' }
    },
    beforeEnter: auth,

  },

  {
    path: '/faq/category/list',
    name: 'category-list',
    component: () => import('@/views/faq/category-list/CategoryList.vue'),
    meta: {
      pageTitle: "Category-List",
      breadcrumb: [
        {
          text: "Category",
          active: true
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'FAQ', type: 'list' }
    },
    beforeEnter: auth,

  },

  {
    path: '/faq/category/add',
    name: 'category-add',
    component: () => import('@/views/faq/category-add/CategoryAddEdit.vue'),
    meta: {
      pageTitle: "Category-Add",
      breadcrumb: [
        {
          text: "Category",
          to: '/faq/category/list'
        },
        {
          text: "Add",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'FAQ', type: 'list' }
    },
    beforeEnter: auth,

  },

  {
    path: '/faq/category/edit/:id',
    name: 'category-edit',
    component: () => import('@/views/faq/category-add/CategoryAddEdit.vue'),
    meta: {
      pageTitle: "Category-Edit",
      breadcrumb: [
        {
          text: "Category",
          to: '/faq/category/list'
        },
        {
          text: "Edit",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'FAQ', type: 'list' }
    },
    beforeEnter: auth,

  },

  {
    path: '/faq/add',
    name: 'faq-add',
    component: () => import('@/views/faq/faq-add/FaqAddEdit.vue'),
    meta: {
      pageTitle: "FAQ-Add",
      breadcrumb: [
        {
          text: "FAQ",
          to: '/faq/list'
        },
        {
          text: "Add",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Faq', type: 'add' }
    },
    beforeEnter: auth,

  },

  {
    path: '/faq/edit/:id',
    name: 'faq-edit',
    component: () => import('@/views/faq/faq-add/FaqAddEdit.vue'),
    meta: {
      pageTitle: "FAQ-Edit",
      breadcrumb: [
        {
          text: "FAQ",
          to: '/faq/list'
        },
        {
          text: "Edit",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'FAQ', type: 'edit' }

    },
    beforeEnter: auth,
  }
]
