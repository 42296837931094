import auth from '@/middleware/auth'

export default [
  {
    path: '/offer/add/:id',
    name: 'add-offer',
    component: () => import('@/views/offer/add-offer/OfferAddEdit.vue'),
    meta: {
      pageTitle: "Create Offer",
      breadcrumb: [
        {
          text: "Offer",
          to: '/offer/list'
        },
        {
          text: "Add",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Offers', type: 'add' }
    },
    beforeEnter: auth,

  },

  {
    path: '/offer/list',
    name: 'offer-list',
    component: () => import('@/views/offer/offer-list/OfferList.vue'),
    meta: {
      pageTitle: "Offer List",
      breadcrumb: [
        {
          text: "Offer",
          active: true
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Offers', type: 'list' }
    },
    beforeEnter: auth,

  },

  {
    path: '/offer/edit/:id',
    name: 'edit-offer',
    component: () => import('@/views/offer/add-offer/OfferAddEdit.vue'),
    meta: {
      pageTitle: "Edit Offer",
      breadcrumb: [
        {
          text: "Edit",
          to: '/offer/list'
        },
        {
          text: "Offer",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Offers', type: 'edit' }
    },
    beforeEnter: auth,

  },
]
