import auth from "@/middleware/auth";

export default [
  {
    path: "/content/static-content",
    name: "static-content",
    component: () =>
      import(
        "@/views/content-admin/static-content/static-list/StaticContentList.vue"
      ),
    meta: {
      pageTitle: "Static-Content",
      breadcrumb: [
        {
          text: "Static-Content",
          active: true,
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: "Static content", type: "list" },
    },
    beforeEnter: auth,
  },

  {
    path: "/content/edit-static-content/:id",
    name: "static-content-edit",
    component: () =>
      import(
        "@/views/content-admin/static-content/static-edit/StaticContentEdit.vue"
      ),
    meta: {
      pageTitle: "Static-Content",
      breadcrumb: [
        {
          text: "Static-Content",
          to: { name: "static-content" },
        },
        {
          text: "Edit",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: "Static content", type: "edit" },
    },
    beforeEnter: auth,
  },

  // ------------------Keywords Routes-------------------

  {
    path: "/content/keywords",
    name: "keyword-translation",
    component: () =>
      import("@/views/content-admin/keywords/KeywordTranslation.vue"),
    meta: {
      pageTitle: "Keyword Translation",
      breadcrumb: [
        {
          text: "Keyword Translation",
          active: true,
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: "Keyword Translation", type: "list" },
    },
    beforeEnter: auth,
  },

  // ------------------ Remarks Routes-----------------------

  {
    path: "/content/remarks",
    name: "remarks",
    component: () =>
      import("@/views/content-admin/remarks/remarks-edit/RemarksEdit.vue"),
    meta: {
      pageTitle: "Remarks",
      breadcrumb: [
        {
          text: "Remarks",
          active: true,
        },
        {
          text: "Remarks-Edit",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: "Remarks", type: "list" },
    },
    beforeEnter: auth,
  },

  // --------------Local Tax Translation-----------------------

  {
    path: "/content/local-tax",
    name: "local-tax",
    component: () =>
      import(
        "@/views/content-admin/local-tax-translation/local-tax-list/LocalTaxList.vue"
      ),
    meta: {
      pageTitle: "Local Tax Translation",
      breadcrumb: [
        {
          text: "Local Tax Translation",
          active: true,
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: "Local-Tax", type: "list" },
    },
    beforeEnter: auth,
  },

  {
    path: "/content/local-tax-edit/:id",
    name: "local-tax-edit",
    component: () =>
      import(
        "@/views/content-admin/local-tax-translation/local-tax-edit/LocalTaxAddEdit.vue"
      ),
    meta: {
      pageTitle: "Local Tax Translation",
      breadcrumb: [
        {
          text: "Local Tax Translation",
          to: { name: "local-tax" },
        },
        {
          text: "Edit",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: "Local-Tax", type: "edit" },
    },
    beforeEnter: auth,
  },

  // --------------- Fraud Check -----------------

  {
    path: "/content/fraud-check",
    name: "fraud-check",
    component: () => import("@/views/content-admin/fraud-check/FraudCheck.vue"),
    meta: {
      pageTitle: "Fraud Check",
      breadcrumb: [
        {
          text: "Fraud Check",
          active: true,
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: "Fraud-Check", type: "list" },
    },
    beforeEnter: auth,
  },

  // ---------------------Promotion Banner---------------
  {
    path: "/content/promotion-banner/list",
    name: "promotion-banner-list",
    component: () =>
      import(
        "@/views/content-admin/promotion-banner/promotion-list/PromotionBannerList.vue"
      ),
    meta: {
      pageTitle: "Promotion Banner List",
      breadcrumb: [
        {
          text: "Promotion Banner",
          active: true,
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: "Promotion-Banner", type: "list" },
    },
    beforeEnter: auth,
  },

  {
    path: "/content/promotion-banner/add",
    name: "promotion-banner-add",
    component: () =>
      import(
        "@/views/content-admin/promotion-banner/promotion-add/PromotionAddEdit.vue"
      ),
    meta: {
      pageTitle: "Promotion Banner Add",
      breadcrumb: [
        {
          text: "Promotion Banner",
          to: { name: "promotion-banner-list" },
        },
        {
          text: "Add",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: "Promotion Banner", type: "add" },
    },
    beforeEnter: auth,
  },

  {
    path: "/content/promotion-banner/edit/:id",
    name: "promotion-banner-edit",
    component: () =>
      import(
        "@/views/content-admin/promotion-banner/promotion-add/PromotionAddEdit.vue"
      ),
    meta: {
      pageTitle: "Promotion Banner Edit",
      breadcrumb: [
        {
          text: "Promotion Banner",
          to: { name: "promotion-banner-list" },
        },
        {
          text: "Edit",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: "Promotion Banner", type: "edit" },
    },
    beforeEnter: auth,
  },

  // --------------- Coupon Code -----------------

  {
    path: "/content/coupon-code",
    name: "coupon-code",
    component: () =>
      import("@/views/content-admin/coupon-code/CouponCodeList.vue"),
    meta: {
      pageTitle: "Coupon Code",
      breadcrumb: [
        {
          text: "Coupon Code",
          active: true,
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: "Coupon Code", type: "list" },
    },
    beforeEnter: auth,
  },

  // ---------------------Airline Baggage---------------
  {
    path: "/content/airline-baggage/list",
    name: "airline-baggage-list",
    component: () =>
      import("@/views/content-admin/airline-baggage/AirlineBaggage.vue"),
    meta: {
      pageTitle: "Airline Baggage List",
      breadcrumb: [
        {
          text: "Airline Baggage",
          active: true,
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: "Airline-Baggage", type: "list" },
    },
    beforeEnter: auth,
  },

  // --------------- Resort Translation -----------------

  {
    path: "/content/resort-translation",
    name: "resort-translation",
    component: () =>
      import(
        "@/views/content-admin/resort-translation/ResortTranslationList.vue"
      ),
    meta: {
      pageTitle: "Resort Translation",
      breadcrumb: [
        {
          text: "Resort Translation",
          active: true,
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: "Resort Translation", type: "list" },
    },
    beforeEnter: auth,
  },

  // ------------------Error Translation Routes-------------------

  {
    path: "/content/error-translation",
    name: "error-translation",
    component: () =>
      import(
        "@/views/content-admin/error-validation/ErrorValidationTranslation.vue"
      ),
    meta: {
      pageTitle: "Error Translation",
      breadcrumb: [
        {
          text: "Error Translation",
          active: true,
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: "Error Translation", type: "list" },
    },
    beforeEnter: auth,
  },
];
