import auth from "@/middleware/auth";

export default [
  {
    path: '/logs-list',
    name: 'logs',
    component: () => import('@/views/logs/logs-list/LogsList.vue'),
    meta: {
      pageTitle: "Logs",
      breadcrumb: [
        {
          text: "Logs",
          active: true
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Logs', type: 'list' }
    },
    beforeEnter: auth,

  },

]
