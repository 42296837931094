import auth from "@/middleware/auth";

export default [
  {
    path: '/user-rights',
    name: 'user-rights',
    component: () => import('@/views/user-rights/UserRights.vue'),
    meta: {
      pageTitle: "User-Rights",
      breadcrumb: [
        {
          text: "User-Rights",
          active: true
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'User-Rights', type: 'list' }
    },
    beforeEnter: auth,

  },

]
