import auth from '@/middleware/auth'

export default [
  {
    path: '/booking/list',
    name: 'booking-list',
    component: () => import('@/views/booking/booking-list/BookingList.vue'),
    meta: {
      pageTitle: "Booking",
      breadcrumb: [
        {
          text: "Booking",
          active: true,
        },
        {
          text: "List",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Booking', type: 'list' }
    },
    beforeEnter: auth,

  },

  {
    path: '/booking/edit/:id',
    name: 'booking-details',
    component: () => import('@/views/booking/booking-detail/BookingDetail.vue'),
    meta: {
      pageTitle: "Booking",
      breadcrumb: [
        {
          text: "Booking",
          to: { name: 'booking-list' },
        },
        {
          text: "Detail",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Booking', type: 'detail' }
    },
    beforeEnter: auth,

  },

  // ----------- Manual Booking ----------------------

  {
    path: '/booking/manual-booking/',
    name: 'new-booking',
    component: () => import('@/views/booking/manual-booking/new-booking/NewBooking.vue'),
    meta: {
      pageTitle: "Booking",
      breadcrumb: [
        {
          text: "Booking",
          to: { name: 'booking-list' },
        },
        {
          text: "New Booking",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Booking', type: 'new' }
    },
    beforeEnter: auth,

  },

  {
    path: '/booking/manual-booking/booking-details/:id',
    name: 'new-booking-details',
    component: () => import('@/views/booking/manual-booking/new-booking-details/NewBookingDetails.vue'),
    meta: {
      pageTitle: "Booking",
      breadcrumb: [
        {
          text: "Booking",
          to: { name: 'booking-list' },
        },
        {
          text: "New Booking Details",
          active: true,
        },
      ],
      requiresAuth: true,
      module: { name: 'Booking', type: 'new-details' }
    },
    beforeEnter: auth,

  },
]
